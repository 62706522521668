<template>
  <nav class="guide">
    <div class="guide_wrap">
      <div class="box">
        <router-link
          class="logo_box"
          :to="{ path: '/' }"
        >
          <img :src="data.logoUrl" alt="" class="img" />
        </router-link>
        <div class="menu_box">
          <div class="line" :style="{ left: data.lineLeft + '%' }"></div>
          <h1
            v-for="(item, i) in data.guideList"
            :key="item.id"
            class="item"
            :class="{ active: data.activeId == item.id }"
            @mouseenter.self="lineCtrl(i)"
            @mouseleave.self="lineCtrl(data.activeIndex)"
            @click="nav(item.path)"
          >
            <div v-if="item.icon" class="title icon" @mouseleave.prevent.stop="doNone">
              <svg-icon :icon-class="'sousuo'"></svg-icon>
            </div>
            <div v-else class="title" @mouseleave.prevent.stop="doNone">
              {{ item.title }}
            </div>
            
          </h1>
        </div>
      </div>
    </div>
  </nav>
</template>
<script lang="ts">
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'Guide',
  setup() {
    const data = reactive({
      activeId: 0,
      logoUrl: '/static/images/logo2.png',
      lineLeft: 0,
      activeIndex: 0,
      guideList: [
        { id: 0, title: '主页', path: '/', icon: false },
        { id: 1, title: '音乐', path: '/music', icon: false },
        { id: 2, title: '旅行', path: '/travel', icon: false },
        { id: 3, title: '游戏', path: '/gamelist', icon: false },
        { id: 4, title: '搜索', path: '/search', icon: true },
      ],
    });

    const lineCtrl = (index: number) => {
      data.lineLeft = 22.5 * index;
    };

    const doNone = () => {
      // empty
    };

    const router = useRouter();
    const nav = (path: string) => {
      router.push({
        path,
      });
    };

    const index = data.guideList.findIndex(item => {
      return item.path === router.currentRoute.value.path;
    });

    if (index >= 0) {
      data.activeId = data.guideList[index].id;
      data.activeIndex = index;
      lineCtrl(index);
    }

    return { data, lineCtrl, doNone, nav };
  },
});
</script>
<style lang="less" scoped>
@import "../../styles/config.less";
.guide {
  width: 100%;
  height: 0.6rem;
  .guide_wrap {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.6rem;
    background-color: @c_bg_tips;
    box-shadow: 0 0.05rem 0.1rem 0 rgba(0, 0, 0, 0.1);
    .box {
      width: 100%;
      height: 100%;
      max-width: @minWidth;
      margin: auto;
      padding: 0 0.3rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .logo_box {
        // width: 1.5rem;
        height: 80%;
        margin-right: 20%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        .img {
          display: block;
          // width: 100%;
          height: 80%;
          object-fit: contain;
        }
        .text {
          font-size: @f_title_1;
          color: @c_main;
          line-height: 1.5;
          font-weight: bold;
        }
      }
      .menu_box {
        position: relative;
        height: 100%;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .line {
          position: absolute;
          top: 0;
          left: 0;
          width: 10%;
          height: 0.03rem;
          background-color: @brand-danger;
          transition: all 0.5s;
        }
        .item {
          height: 100%;
          width: 10%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &:hover {
            background-color: @c_bg_active;
            .title{
              color: @brand-danger;
            }
          }
          .title {
            font-size: @f_title_2;
            color: fade(@brand-danger, 60);
            line-height: 1.5;
            font-weight: bold;
            white-space: nowrap;
            &.icon{
              font-size: @f_title_1;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .guide {
    .guide_wrap {
      height: 0.5rem;
      .box {
        padding: 0 0.05rem;
        .logo_box {
          display: none;
          margin-right: 5%;
        }
        .menu_box {
          .item {
            .title {
              font-size: @f_title_3;
            }
          }
        }
      }
    }
  }
}
</style>
