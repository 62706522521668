<template>
  <div :class="svgClass">
    <i :class="iconName"></i>
  </div>
</template>

<script lang="ts">
import { computed } from 'vue';
export default defineComponent({
  name: 'ComponentsSvgIcon',
  props: {
    iconClass: {
      type: String,
      default: '',
    },
    className: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const iconName = computed(() => {
      return `iconfont icon-${props.iconClass}`;
    });
    
    const svgClass = computed(() => {
      if (props.className) {
        return `svg-icon ${props.className}`;
      }

      return '';
    });

    return { iconName, svgClass };
  },
});
</script>

<style scoped lang="less">
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  display: flex;
  align-items: center;
  justify-content: center;
}
.iconfont {
  font-size: 1em;
}
</style>
